import { Link } from "react-router-dom";
import App from "../../App";

function Home() {
  return (
    <App>
    <main>
        <header>
          <h1>
            <span className='line1'>Supercharging your Agile</span><br></br>
            <span className='line2'>Make Your Meetings <br></br>More Meaningful</span>
          </h1>
          <br></br>
          <Link to="/" className='button outline'>Coming Soon</Link>
        </header>
      </main>
      <div className="dock">
        <a href="#">
          <img src="images/daily_standup.png"/> 
          <p>Daily Standups
          <span className='caption'>Coming Soon</span></p>
        </a>
        <a href="#">
          <img src="images/planning_poker.png"/>
          <p>Planning Poker
          <span className='caption'>Coming 1/22/24</span></p>
        </a>
        <a href="#"><img src="images/retrospective.png"/>
          <p>Retrospectives <span className='caption'>Coming Soon</span></p>
        </a>
      </div>
    </App>
  );
}

export default Home;

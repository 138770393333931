import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import App from "../../App";

function Login() {

    const [error, setError] = useState('')
    const [loading, setLoading] = useState('')

    const navigate = useNavigate()

    async function submitSignUp(e){
      e.preventDefault()
      setError("Incorrect Credentials")
    }


    return (
      <App showSignUp={true}>
        <main className="account">
          <header>
            <h1>
              <span className='line1'>Welcome</span><br></br>
              <span className='line2'>Login</span>
            </h1>
            <br></br>
            <form onSubmit={submitSignUp} className="account">
              <input type="email" placeholder="Email" required/>
              <input type="password" placeholder="Password" required/>
              <Link to="/forgot_password">Forgot Password?</Link>
              {error && <p className="error">{error}</p>}
              <button disabled={loading} type="submit" href="/signup" className='button'>Login <span className="material-symbols-outlined">arrow_forward</span></button>
            </form>
          </header>
        </main>
        <footer>
          <Link to="/signup">Or Signup Instead</Link>
        </footer>
      </App>
    );
  }
  
  export default Login;
  
import './styles/App.css';
import Logo from './images/logo.svg';
import { Link, useNavigate } from 'react-router-dom';

function App(props) {

  // const { currentUser, logout } = useAuth()
  const navigate = useNavigate()

  async function handleLogout() {
    try{
      // await logout()
      navigate("/login")
    } catch {
      
    }
  }

  return (
    <div className='page'>
      <nav>
        <div className="llinks">
          <Link className='notif'><span className="icon">✈️</span>Currently Pilot-Testing</Link>
        </div>
        <Link to="/" className='logo'><img src={Logo} alt='Logo'/></Link>
        <div className="rlinks">
          
          {/* {currentUser && <Link to="/dashboard">Dashboard</Link>} */}
          {(props.showSignUp ? <Link to="/signup" className='primary'>Or Signup</Link> : <Link to="/login" className='primary'>Login</Link>)}
        </div>
      </nav>
      {props.children}
    </div>
  );
}

export default App;

import { useEffect, useRef, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import App from "../../App";

function ForgotPassword() {

    const emailRef = useRef()

    const [error, setError] = useState('')



    async function submitSignUp(e){
      // e.preventDefault()
      
      // try{
      //   setError()
      //   setLoading(true)
      //   await resetPassword(emailRef.current.value)
      //   setError("Check Inbox for Reset Link")
      // } catch {
        setError("No Account with Email")
      // }
      // setLoading(false)
    }

    return (
      <App>
        <main className="account">
          <header>
            <h1>
              <span className='line2'>Forgot Password?</span>
            </h1>
            <br></br>
            <form onSubmit={submitSignUp} className="account">
              <input type="email" placeholder="Email" ref={emailRef} required/>
              {error && <p className="error">{error}</p>}
              <button type="submit" href="/signup" className='button'>Send Reset Link<span className="material-symbols-outlined">arrow_forward</span></button>
            </form>
          </header>
        </main>
        <footer>
          <Link to="/login">Or Login Instead</Link>
        </footer>
      </App>
    );
  }
  
  export default ForgotPassword;
  
import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import App from "../../App";

function Signup() {

    const [error, setError] = useState('')

    async function submitSignUp(e){
      setError("Coming Soon")
    }

    return (
      <App>
      <main className="account">
          <header>
            <h1>
              <span className='line1'>Welcome</span><br></br>
              <span className='line2'>Signup</span>
            </h1>
            <br></br>
            <form onSubmit={submitSignUp} className="account">
              <input type="email" placeholder="Email"required/>
              <input type="password" placeholder="Password" required/>
              <input type="password" placeholder="Confirm Password" required/>
              {error && <p className="error">{error}</p>}
              <button type="submit" href="/signup" className='button'>Sign Up <span className="material-symbols-outlined">arrow_forward</span></button>
            </form>
          </header>
        </main>
        <footer>
          <Link to="/login">Or Login Instead</Link>
        </footer>
      </App>
    );
  }
  
  export default Signup;
  